<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Comparativa mensajeria mes actual vs mes anterior</b-card-title>
      <feather-icon
        icon="DollarSignIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            Mes actual
          </b-card-text>
          <h3 class="font-weight-bolder">
            <span class="text-primary">{{ currencyFormat(total_current_month) }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50">
            Mes anterior
          </b-card-text>
          <h3 class="font-weight-bolder">
            <span>{{ currencyFormat(total_last_month) }}</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="data.chartOptions"
        :series="data.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

import {
  currencyFormat
} from '@/helpers'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: ['data', 'total_current_month', 'total_last_month'],
  data() {
    return {
      revenue: {},
    }
  },
  methods: {
    currencyFormat(amount) {
      return currencyFormat(amount)
    }
  }
}
</script>
