<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          md="8"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              Ganancias por mes en {{ year }}
            </h4>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="230"
            :options="revenueReport.chartOptions"
            :series="data.series"
          />
        </b-col>

        <b-col
          md="4"
          class="budget-wrapper"
        >
          <b-dropdown
            :text="`${year}`"
            size="sm"
            class="budget-dropdown"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-for="y in years"
              :key="y"
              :disabled="y == year"
              @click="changeYear(y)"
            >
              {{ y }}
            </b-dropdown-item>
          </b-dropdown>

          <div class="d-flex justify-content-center">
            <span class="font-weight-bolder mr-25">Total en el año</span>
          </div>
          <h2 class="mb-25">
            {{ totalYear }}
          </h2>

        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BOverlay
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

import {
  currencyFormat
} from '@/helpers'

export default {
  components: {
    BOverlay,
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    totalYear: {
      default: 0
    },
    year: {
      type: Number,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false }
            },
          },
          xaxis: {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              }
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
              formatter(val) {
                return currencyFormat(val)
              }
            },
          },
        },
      },

      years: [],
      start_year: 2021,
    }
  },
  methods: {
    changeYear(y) {
      this.$emit('changeYear', y)
    }
  },
  mounted() {
    let today = new Date();
    let current_year = today.getFullYear()
    for (let index = 0; index < 5; index++) {
      if (this.start_year + index <= current_year) {
        this.years.push(this.start_year + index)
      }
    }
  }
}
</script>
