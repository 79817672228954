<template>
  <div>
    <b-overlay :show="loading" rounded="sm">

      <div class="row mb-1">
        <div class="col-2">
          <date-picker
            range-separator="-"
            v-model="rangePicker"
            :default-value="rangePicker"
            range
            append-to-body
            lang="es"
            format="YYYY-MM-DD"
            style="width: 100%"
          ></date-picker>
        </div>
      </div>

      <div class="row">
        <div class="col-7">
          <card-statistics
            :due_addons="due_addons"
            :due_services="due_services"
            :due_total="due_total"
          ></card-statistics>
          <orders-in-course :tableData="orders_in_course"/>
          <hr>
          <earnings-by-month-year
            :data="earnings_by_month"
            :year="year"
            :totalYear="currencyFormat(totalYear)"
            :loading="loadingReportYear"
            @changeYear="changeYear"
          />
        </div>
        <div class="col-5">
          <top-ten-clients
            :data="top_ten_clients"
          />
          <top-ten-distributors
            :data="top_ten_distributors"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6">

        </div>
      </div>

      <div class="row">
        <div class="col">
          <comparative-earnings
            :data="chartDataComparative"
            :total_current_month="totalCurrentMonth"
            :total_last_month="totalLastMonth"
          />
        </div>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import DashboardService from '@/services/dashboard.service'

import CardStatistics from './dashboard/CardStatistics'
import TopTenClients from './dashboard/TopTenClients'
import OrdersInCourse from './dashboard/OrdersInCourse'
import EarningsByMonthYear from './dashboard/EarningsByMonthYear'
import TopTenDistributors from './dashboard/TopTenDistributors'
import ComparativeEarnings from './dashboard/ComparativeEarnings'


import { $themeColors } from '@themeConfig'
import DatePicker from 'vue2-datepicker';

import {
  BOverlay,
  BCard
} from 'bootstrap-vue'

import {
  currencyFormat
} from '@/helpers'

export default {
  components: {
    ComparativeEarnings,
    BCard,
    TopTenDistributors,
    EarningsByMonthYear,
    OrdersInCourse,
    TopTenClients,
    CardStatistics,
    BOverlay,
    DatePicker
  },
  data() {
    return {
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      orders_in_course: [],
      due_services: 0,
      due_addons: 0,
      due_total: 0,
      top_ten_clients: [],
      top_ten_distributors: [],
      earnings_comparative: [],
      loading: false,
      earnings_by_month: {
        series: []
      },
      year: new Date().getFullYear(),
      totalYear: 0,
      loadingReportYear: false,

      current_month: new Date().getMonth() + 1,
      last_month: new Date().getMonth(),

      chartDataComparative: {
        series: [
          {
            name: 'Mes actual',
            data: [],
          },
          {
            name: 'Mes pasado',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.info, $themeColors.primary, ],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.info, $themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return currencyFormat(val)
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      totalCurrentMonth: 0,
      totalLastMonth: 0
    }
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getDashboard()
      }
    }
  },
  methods: {
    async getDashboard() {
      try {
        this.loading = true
        const { data:res } = await DashboardService.getDashboard(this.rangePicker)

        this.due_services = res.due.services
        this.due_addons = res.due.addons
        this.due_total = res.due.total
        this.orders_in_course = res.orders_in_course
        this.top_ten_clients = res.top_ten_clients
        this.top_ten_distributors = res.top_ten_distributors.top_ten

        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async getEarningByMonth() {
      try {

        this.loadingReportYear = true

        const { data:res } = await DashboardService.getEarningsByMonth(this.year)

        let earnings_current_year = { name: 'Pagado en mensajeria', data: [0,0,0,0,0,0,0,0,0,0,0,0]}

        for (let item in res.data) {
          let index
          if (item.substr(0, 1) == '0') {
            index = item.substr(-1, 1) - 1
          } else {
            index = item - 1
          }
          res.data[item].forEach(i => {
            earnings_current_year.data[index] += parseFloat(i.payment_services)
            this.totalYear += parseFloat(i.payment_services)
          })
        }

        this.earnings_by_month.series.push(earnings_current_year)
        this.loadingReportYear = false
      } catch (error) {
        this.loadingReportYear = false
        this.swal('Ha ocurrido un error', '', 'warning')
      }
    },
    async getEarningsComparative() {
      try {
        const { data:res } = await DashboardService.getEarningsComparative()

        let earnings_current_month = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        let earnings_last_month =    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

        const orders_current_month = res.data.current_month
        const orders_last_month = res.data.last_month

        this.totalCurrentMonth = parseFloat(res.data.total_current_month)
        this.totalLastMonth = parseFloat(res.data.total_last_month)

        for (let item in orders_current_month) {
          const index = this.removeZeroForIndex(item)
          orders_current_month[item].forEach(i => {
            earnings_current_month[index] += parseFloat(i.total)
            /*let amount = 0
            if (i.payment_type == 'all') {

              //si el monto pagado es diferente al total en compras se toma lo que tiene pagado en servicios
              if (i.amount != i.order.additional_total) {
                amount = i.order.payment_services;

              //si el monto pagado es igual al total en servicios mas extras se toma lo pagado en servicios
              } else if (i.amount == i.order.total_more_extras) {
                amount = i.order.payment_services;

              //si el monto pagado es igual al total en servicios se toma lo pagado en servicios
              } else if (i.amount == i.order.total) {
                amount = i.order.payment_services;
              }

              earnings_current_month[index] += parseFloat(amount)
            } else {
              amount = i.amount
              earnings_current_month[index] += parseFloat(amount)
            }*/
          })
        }

        for (let item in orders_last_month) {
          const index = this.removeZeroForIndex(item)
          orders_last_month[item].forEach(i => {
            earnings_last_month[index] += parseFloat(i.total)
            /*let amount = 0
            if (i.payment_type == 'all') {
              //si el monto pagado es diferente al total en compras se toma lo que tiene pagado en servicios
              if (i.amount != i.order.additional_total) {
                amount = parseFloat(i.order.payment_services);

              //si el monto pagado es igual al total en servicios mas extras se toma lo pagado en servicios
              } else if (i.amount == i.order.total_more_extras) {
                amount = parseFloat(i.order.payment_services);

              //si el monto pagado es igual al total en servicios se toma lo pagado en servicios
              } else if (i.amount == i.order.total) {
                amount = parseFloat(i.order.payment_services);
              }
              earnings_last_month[index] += parseFloat(amount)
            } else {
              amount = i.amount
              earnings_last_month[index] += parseFloat(amount)
            }*/

          })
        }

        this.chartDataComparative.series[1].data = earnings_last_month
        this.chartDataComparative.series[0].data = earnings_current_month

        this.chartDataComparative.series = JSON.parse(JSON.stringify(this.chartDataComparative.series))
      } catch (error) {
        console.log(error)
        //this.swal('Ha ocurrido un error', '', 'warning')
      }
    },
    removeZeroForIndex(item) {
      let first_digit = item.substr(0, 1)
      if (first_digit == '0') {
        return item.replace('0', '', item) - 1
      } else {
        return item - 1
      }
    },
    changeYear(year) {
      this.year = year
      this.getEarningByMonth()
    },
    currencyFormat(amount) {
      return currencyFormat(amount)
    }
  },
  created() {
    this.getDashboard()
    this.getEarningByMonth()
    this.getEarningsComparative()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
