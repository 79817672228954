import api from '@/api'

class DashboardService {

  static getDashboard(daterange) {
    return api({
      url: 'dashboard',
      params: { daterange }
    })
  }

  static getEarningsByMonth(year) {
    return api({
      url: 'earnings-by-month',
      params: { year }
    })
  }

  static getEarningsComparative() {
    return api({
      url: 'earnings-comparative'
    })
  }

}

export default DashboardService
